import axios from 'axios';
import Storage from './Storage';

export const apiUrl = (apiMethod) => {
  return process.env.REACT_APP_SERVER_URL + "/api/admin/" + apiMethod;
}

export const authUrl = (apiMethod) => {
  return process.env.REACT_APP_SERVER_URL + "/api/v1/" + apiMethod;
}

export const WSUrl = () => {
  return process.env.REACT_APP_SERVER_URL.replace(/^http/, 'ws')
    + '/cable'
    + '?token=' + localStorage.getItem('meter-inspector-admin:token');
}

export const getUrlParameter = (sParam, location) => {
  location = location || window.location.search;
  var sPageURL = decodeURIComponent(location.substring(1));
  var sURLVariables = sPageURL.split('&');
  for (let i = 0; i < sURLVariables.length; i++) {
    var sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
      return sParameterName[1] === undefined ? true : sParameterName[1];
    }
  }
  return false;
};


const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

const CACHE_CONFIG = [
  {
    include: [
      /.+\/all/,
    ],
    exclude: [],
    expire: 30 * MINUTE,
  },
];

export const getMethodString = (url, data) => {
  const paramString = new URLSearchParams(data || {}).toString();
  if (paramString) {
    return `${url}${url.includes('?') ? '&' : '?'}${paramString}`;
  } else {
    return `${url}`;
  }
}

const getCacheKey = (type, methodString) => {
  return `cache:${type}:${methodString}`;
}

const getCachedData = (type, methodString) => {
  const cacheKey = getCacheKey(type, methodString);
  const cached = Storage.getData(cacheKey);
  if (cached) {
    if (cached.expire < Date.now()) {
      Storage.setData(cacheKey, null);
    } else {
      return { data: cached.data, update: cached.update };
    }
  }
  return null;
}

const cacheData = (properties, responseData) => {
  const { method, data, noCache, responseType, auth } = properties;
  const type = (properties.type || 'get').toUpperCase();
  const url = apiUrl(method);
  if (!noCache && !auth && type === 'GET' && responseType !== 'blob') {
    const methodString = getMethodString(url, data);
    const conf = CACHE_CONFIG.find(i => {
      const include = i.include.find(r => methodString.match(r));
      const exclude = i.exclude.find(r => methodString.match(r));
      return include && !exclude;
    });
    if (conf) {
      const cacheKey = getCacheKey(type, methodString);
      Storage.setData(cacheKey, {
        data: responseData,
        expire: Date.now() + conf.expire,
        update: conf.update,
      });
    }
  }
}

export const sendRequest = (properties) => {
  const { method, data, noCache, responseType, auth } = properties;
  const type = (properties.type || 'get').toUpperCase();
  const url = apiUrl(method);
  let cached = null;
  if (!noCache && !auth && type === 'GET' && responseType !== 'blob') {
    const methodString = getMethodString(url, data);
    cached = getCachedData(type, methodString);
  }
  if (cached) {
    if (cached.update) {
      const { success, error, ...restProperties } = properties;
      sendServerRequest({...restProperties, noLoad: true});
    }
    properties.success(cached.data, { headers: { cache: true } });
  } else {
    sendServerRequest(properties);
  }
}

const sendServerRequest = (properties) => {
  const token = localStorage.getItem('meter-inspector-admin:token');
  const url = properties.auth ? authUrl(properties.method) : apiUrl(properties.method);
  const type = (properties.type || 'get').toUpperCase();
  if (!properties.noLoad) {
    triggerEvent('addLoad');
  }
  const headers = {};
  if (token) {
    headers['Authorization'] =  'Bearer ' + token;
  }
  if (properties.formData) {
    headers['enctype'] = 'multipart/form-data';
  }
  axios({
    url,
    method: type,
    data: type === 'GET' ? null : (properties.data || properties.formData),
    params: type === 'GET' ? properties.data : null,
    headers,
    responseType: properties.responseType,
    onUploadProgress: e => {
      if (properties.progress) {
        properties.progress(e.loaded / e.total);
      }
    }
  })
    .then(response => {
      if (!properties.noLoad) {
        triggerEvent('removeLoad');
      }
      if (response.status >= 200 && response.status < 400) {
        if (properties.success) {
          cacheData(properties, response.data);
          properties.success(response.data, response);
        }
      }
    })
    .catch(error => {
      if (!properties.noLoad) {
        triggerEvent('removeLoad');
      }
      if (error.response) {
        if (error.response.status === 401) {
          triggerEvent('unauthorized');
        }
        if (error.response.status === 403) {
          triggerEvent('forbidden');
        }
        if (properties.error) {
          properties.error(error.response.data);
        }
      } else if (error.request) {
        if (properties.error) {
          properties.error('');
        }
      } else {
        if (properties.error) {
          properties.error('');
        }
      }
    })
}

export const triggerEvent = (name, data) => {
  let event;
  if (window.CustomEvent) {
    event = new CustomEvent(name, {detail: data});
  } else {
    event = document.createEvent('CustomEvent');
    event.initCustomEvent(name, true, true, data);
  }
  document.dispatchEvent(event);
}

export const triggerNativeEvent = (elem, name) => {
  var event = document.createEvent('HTMLEvents');
  event.initEvent(name, true, false);
  elem.dispatchEvent(event);
}

export const triggerClick = (elem) => {
  var event = document.createEvent('MouseEvents');
  event.initMouseEvent('click',true,true,window,0,0,0,0,0,false,false,false,false,0,null);
  elem.dispatchEvent(event);
}

export const eventOn = (name, handler, parent) => {
  parent = parent || document;
  if (handler) {
    parent.addEventListener(name, handler);
  }
}

export const eventOff = (name, handler, parent) => {
  parent = parent || document;
  if (handler) {
    parent.removeEventListener(name, handler);
  }
}

export const validateEmail = (email) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const compareStringNums = (a, b) => {
  const numA = Number((a || '').replaceAll(/\D/g, '')) || 0;
  const numB = Number((b || '').replaceAll(/\D/g, '')) || 0;
  if (numA !== numB) {
    return numA - numB;
  }
  return (a || '').localeCompare(b || '');
}

export const fileToImageData = (src, maxSize, handler) => {
  const reader = new FileReader();
  reader.onloadend = (rs) => {
    const imageData = reader.result;
    
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const img = document.createElement('img');
    img.onload = () => {
      const imageSize = Math.max(img.width, img.height);
      const size = Math.min(imageSize, maxSize);
      const k = size / imageSize;
      canvas.width = img.width * k;
      canvas.height = img.height * k;
      context.drawImage(img,
        0, 0, canvas.width, canvas.height
      );
      if (handler) {
        handler(canvas.toDataURL('image/png'), canvas.width, canvas.height);
      }
    }
    img.src = imageData;
  }
  reader.readAsDataURL(src);
}

export const TIME_OPTIONS = [...Array(23)].map((i, index) => {
  const time = 9*60 + index * 30;
  const hours = Math.floor(time / 60);
  const minutes = `${time % 60}`.padStart(2, '0')
  return { id: time, name: `${hours}:${minutes}` }
});
