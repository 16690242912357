import React from 'react';

import '../sass/components/UserAvatarView.scss';

const COLORS = [
  '#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4',
  '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722'
];

export default class UserAvatarView extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  renderLetterAvatar = (user) => {
    let size = this.props.size || 30;
    let name = (user.name || user.email || '');
    let color = COLORS[(user.id + name.length) %  COLORS.length];
    let letter = name.substr(0, 1).toUpperCase();
    return (
      <div
        className='userAvatarView'
        style={{
          width: size,
          height: size,
          backgroundColor: color,
          ...this.props.style,
        }}
      >{letter}</div>
    )
  }

  renderImageAvatar = (image) => {
    let size = this.props.size || 30;
    return (
      <div
        className='userAvatarView'
        style={{
          width: size,
          height: size,
          backgroundImage: `url(${image})`,
          ...this.props.style,
        }}
      />
    )
  }

  render = () => {
    let user = this.props.user || {};
    let image = this.props.image || user.image_url || user.image;
    if (image) {
      return this.renderImageAvatar(image);
    } else {
      return this.renderLetterAvatar(user);
    }
  }

}