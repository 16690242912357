import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { sendRequest } from '../../helpers/global';

import SelectInput from '../input/SelectInput';
import KSKImportTasks from './KSKImportTasks';
import ExportTasks from './ExportTasks';
import ImportTasks from './ImportTasks';
import BypassSheets from './BypassSheets';
import PrintecsExportTasks from './PrintecsExportTasks';
import ActsExport from './ActsExport';
import PhotosExport from './PhotosExport';
import ImportReadings from './ImportReadings';

import '../../sass/components/AdminToolsView.scss';

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class AdminToolsView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      group_id: !props.user?.group?.admin ? props.user?.group?.id : null,
      groups: [],
    }
  }

  componentDidMount = () => {
    this.requestGroups();
  }

  requestGroups = () => {
    sendRequest({
      method: 'groups/all',
      type: 'GET',
      success: (data) => {
        this.setState({ groups: (data || []).filter(g => !g.admin) });
      },
      error: (data) => {
      }
    });
  }

  renderKSKViews = (group) => {
    return (<>
      <KSKImportTasks group_id={group.id} />
      <ExportTasks group={group} />
      <BypassSheets group_id={group.id} />
      <PrintecsExportTasks group_id={group.id} />
      <PhotosExport group_id={group.id} />
    </>)
  }

  renderTNSView = (group) => {
    return (<>
      <ImportTasks group={group} />
      <ImportReadings group={group} />
      <ExportTasks group={group} />
      <BypassSheets group_id={group.id} />
      <PrintecsExportTasks group_id={group.id} />
      <PhotosExport group_id={group.id} />
    </>)
  }

  renderTNSInstView = (group) => {
    return (<>
      <ImportTasks group={group} />
      <ImportReadings group={group} />
      <ExportTasks group={group} />
      <PrintecsExportTasks group_id={group.id} />
      <ActsExport />
      <PhotosExport group_id={group.id} />
    </>)
  }

  render = () => {
    const { group_id, groups } = this.state;
    const group = groups.find(g => g.id === group_id);
    return (
      <div className='adminToolsView'>
        <div className='toolsTitle'>
          <label>Инструменты</label>
        </div>
        <div className='toolSection groupSelect'>
          <label>Группа</label>
          <SelectInput
            properties={{
              clearable: true,
              options: groups,
              placeholder: 'Выбрать',
            }}
            disabled={!this.props.user?.group?.admin}
            object={group_id}
            onChange={(k, value) => this.setState({group_id: value})}
          />
        </div>
        {group?.code === 'кск' ? this.renderKSKViews(group) : null}
        {group?.code === 'тнс' ? this.renderTNSView(group) : null}
        {group?.code === 'тнс_инст' ? this.renderTNSInstView(group) : null}
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(AdminToolsView));
