export default {
  areas:               require('./areas.js').default,
  localities:          require('./localities.js').default,
  streets:             require('./streets.js').default,
  companies:           require('./companies.js').default,

  tasks:               require('./tasks.js').default,
  mass_edit_tool: {
    config: { title: "Массовые действия" }
  },
  client_manager: {
    config: { title: "Кабинет менеджера" }
  },

  users:               require('./users.js').default,
  admin_tools: {
    config: { title: "Инструменты" }
  },

  //settings:          require('./settings.js').default,
}
