import React from 'react';
import moment from 'moment';
import classnames from 'classnames';
import CSSTransition from 'react-transition-group/CSSTransition';

import { sendRequest, eventOn, eventOff } from '../../helpers/global';

import '../../sass/components/common/AuditLogsPopup.scss';

class AuditLogsPopup extends React.Component {
  constructor(props) {
    super();
    this.state = {
      hidden: !props.show,
      show: false,
      config: null,
      properties: null,
      object: null,
      id: null,
      logs: [],
    };
  }

  isTaskListAudit = ({config, id} = this.state) => { 
    return config?.auditableEntity === 'Task' && !id;
  }

  componentDidMount = () => {
    eventOn('showAudit', this.showAudit);
  }

  componentWillUnmount = () => {
    eventOff('showAudit', this.showAudit);
  }

  showAudit = (e) => {
    const { config, properties, object, id } = e.detail[0];
    sendRequest({
      method: this.isTaskListAudit({config, id}) ? 'mass_actions'
        : `audit_logs${id ? '/record_changes' : ''}`,
      data: {
        auditable_type: config.auditableEntity,
        auditable_id: id,
      },
      type: 'GET',
      success: (data) => {
        this.setState({
          hidden: false,
          show: true,
          config,
          properties,
          object,
          id,
          logs: data,
        }); 
      },
      error: (data) => {
      }
    });
  }

  onOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      this.onClose();
    }
  }

  onClose = () => {
    this.setState({show: false});
  }

  renderChangeValue = (value, options) => {
    if (value === null || value === undefined) {
      return <i className='null'>&lt;пусто&gt;</i>
    }
    if (options) {
      let resOptions = options || [];
      if (typeof options === 'function') {
        if (this.state.object) {
          resOptions = options(this.state.object) || [];
        } else {
          resOptions = [];
        }
      }
      value = resOptions.find(i => i.id === value)?.name || value;
    }
    if (typeof value === 'boolean') {
      return <i className='boolean'>
        <span className='material-icons'>{value ? 'check' : 'close'}</span>
        {value ? 'да' : 'нет'}
      </i>
    } else if (isNaN(value) || typeof value === 'string') {
      return <i className='string'>"{value}"</i>
    } else {
      return <i className='number'>{value}</i>
    }
  }

  renderChanges = (changes) => {
    if (!changes?.length) {
      return null;
    }
    const { properties } = this.state;
    return (
      <div className='changes'>
        {changes.map(({column, field, from, to}, index) => 
          <div key={index} className='change'>
            <div className='column'>{properties[column]?.title || field || column}:</div>
            <div className='from'>{this.renderChangeValue(from, properties[column]?.options)}</div>
            <span className='material-icons'>trending_flat</span>
            <div className='to'>{this.renderChangeValue(to, properties[column]?.options)}</div>
          </div>
        )}
      </div>
    )
  }

  renderLogs = () => {
    const { logs, id } = this.state;
    return (
      <table>
        <thead>
          <tr>
            <th width='150px'>Время</th>
            <th width='150px'>Действие</th>
            <th width='180px'>Автор</th>
            {id
              ? <th width='auto'>Изменения</th>
              : <th width='80px'>#</th>
            }
          </tr>
        </thead>
        <tbody>
          {logs.map(i =>
            <tr key={i.id}>
              <td>{moment.unix(i.created_at).format('HH:mm DD.MM.YYYY')}</td>
              <td>{
                i.action === 'destroy' ? 'Удаление' :
                i.action === 'create' ? 'Создание' :
                i.action === 'update' ? 'Редактирвоание' :
                ''
              }</td>
              <td>{i.user?.name}</td>
              <td>{id ? this.renderChanges(i.changes_list) : i.auditable_id}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  renderTaskListLogs = () => {
    const { logs, id } = this.state;
    return (
      <table key={id}>
        <thead>
          <tr>
            <th width='150px'>Время</th>
            <th width='230px'>Действие</th>
            <th width='auto'>Автор</th>
            <th width='140px'>Кол-во записей</th>
            <th width='140px'>Файл импорта</th>
          </tr>
        </thead>
        <tbody>
          {logs.map(i =>
            <tr key={i.id}>
              <td>{moment.unix(i.created_at).format('HH:mm DD.MM.YYYY')}</td>
              <td>{
                i.action === 'import' ? 'Импорт' :
                i.action === 'duplication' ? 'Повторное снятие' :
                i.action === 'editing' ? 'Массовое редактирование' :
                i.action === 'deletion' ? 'Массовое удаление' :
                ''
              }</td>
              <td>{i.user?.name}</td>
              <td>{i.records_count}</td>
              <td>{i.file_url ?
                <a
                  download  
                  target='_blank'
                  rel='noopener noreferrer'
                  href={i.file_url}
                >Скачать файл</a>
              : null}</td>
            </tr>
          )}
        </tbody>
      </table>
    )
  }

  render = () => {
    const { show, config } = this.state;
    return (
      <div
        className={classnames('auditLogsPopup', {
          'hidden': this.state.hidden,
        })}
      >
        <CSSTransition
          in={show}
          timeout={350}
          classNames='fade'
          onEnter={() => this.setState({hidden: false})}
          onExited={() => this.setState({hidden: true})}
        >
          <div
            onClick={this.onOverlayClick}
            className='overlay'
          >
            <div className='popupWindow'>
              <div className='auditTitle'>История изменений</div>
              <div className='auditSubtitle'>{config?.objectName}</div>
              <div className='auditLogs'>
                {this.isTaskListAudit() ? this.renderTaskListLogs() : this.renderLogs()}
              </div>
              <div className='popupControls'>
                <button
                  onClick={this.onClose}
                >Закрыть</button>
              </div>
            </div>
          </div>
        </CSSTransition>
      </div>
    )
  }
}

export default AuditLogsPopup;
