import React from 'react';
import { withRouter } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { sendRequest, compareStringNums } from '../../helpers/global';

import SelectInput from '../input/SelectInput';

import '../../sass/components/AdminToolsView.scss';

class BypassSheets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      area_id: null,
      locality_id: null,
      street_id: null,
      house_building: null,
      areas: [],
      localities: [],
      streets: [],
      houseBuildings: [],
    }
  }

  componentDidMount = () => {
    this.requestAreas();
  }

  requestAreas = () => {
    sendRequest({
      method: 'areas/all',
      type: 'GET',
      success: (data) => {
        this.setState({areas: data});
      },
      error: (data) => {
      }
    });
  }

  requestLocalities = () => {
    sendRequest({
      method: 'localities/for_area',
      type: 'GET',
      data: {
        area_id: this.state.area_id,
      },
      success: (data) => {
        this.setState({localities: data});
      },
      error: (data) => {
      }
    });
  }

  requestStreets = () => {
    sendRequest({
      method: 'streets/for_locality',
      type: 'GET',
      data: {
        locality_id: this.state.locality_id,
      },
      success: (data) => {
        this.setState({streets: data});
      },
      error: (data) => {
      }
    });
  }

  requestHouseBuildings = () => {
    sendRequest({
      method: `streets/${this.state.street_id}/houses_and_buildings`,
      type: 'GET',
      success: (data) => {
        const houseBuildings = data.map(i => {
          const parts = i.split('::');
          return {
            id: i,
            house: parts[0],
            building: parts[1] || '',
            name: parts[0] + (parts[1] ? ` корпус ${parts[1]}` : ''),
          }
        }).sort((a, b) => {
          if (a.house !== b.house) {
            return compareStringNums(a.house, b.house);
          }
          return compareStringNums(a.building, b.building);
        });
        this.setState({houseBuildings});
      },
      error: (data) => {
      }
    });
  }

  requestBypassSheet = () => {
    const { locality_id, street_id, house_building } = this.state;
    const parts = house_building.split('::');
    sendRequest({
      method: 'tasks/bypass_sheets',
      type: 'GET',
      responseType: 'blob',
      data: {
        locality_id,
        street_id,
        house: parts[0],
        building: parts[1] || null,
      },
      success: (data, response) => {
        var fileData = new Blob([data], {
          type: response.headers['content-type'],
        });
        const match = decodeURI(response.headers['content-disposition'])?.match(/filename=(.*)/);
        saveAs(fileData, match?.[1] || Date.now());
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { group_id } = this.props;
    const {
      area_id, locality_id, street_id, house_building,
      areas, localities, streets, houseBuildings,
    } = this.state;
    return (
      <div className='toolSection'>
        <div className='toolName'>Обходной лист</div>
        <SelectInput
          properties={{
            clearable: true,
            options: areas.filter(i => i.group_id === group_id),
            placeholder: 'Район',
          }}
          object={area_id}
          onChange={(k, value) => {
            this.setState(area_id !== value ? {
              area_id: value,
              locality_id: null,
              street_id: null,
              house_building: null,
              localities: [],
              streets: [],
              houseBuildings: [],
            } : {}, this.requestLocalities)
          }}
        />
        <SelectInput
          properties={{
            clearable: true,
            options: localities,
            placeholder: 'Н. пункт',
          }}
          object={locality_id}
          onChange={(k, value) => {
            this.setState(locality_id !== value ? {
              locality_id: value,
              street_id: null,
              house_building: null,
              streets: [],
              houseBuildings: [],
            } : {}, this.requestStreets)
          }}
        />
        <SelectInput
          properties={{
            clearable: true,
            options: streets,
            placeholder: 'Улица',
          }}
          object={street_id}
          onChange={(k, value) => {
            this.setState(street_id !== value ? {
              street_id: value,
              house_building: null,
              houseBuildings: [],
            } : {}, this.requestHouseBuildings)
          }}
        />
        <SelectInput
          properties={{
            clearable: true,
            options: houseBuildings,
            placeholder: 'Дом, корпус',
          }}
          object={house_building}
          onChange={(k, value) => {
            this.setState({house_building: value})
          }}
        />
        <button
          disabled={!area_id || !locality_id || !street_id || (!house_building && houseBuildings.length)}
          onClick={this.requestBypassSheet}
        >Сформировать обходной лист</button>
      </div>
    )
  }
}

export default (withRouter(BypassSheets));
