import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { saveAs } from 'file-saver';

import TextInput from './input/TextInput';
import SelectInput from './input/SelectInput';
import Pagination from './common/Pagination';

import { apiUrl, getMethodString, sendRequest, triggerEvent } from '../helpers/global';

import '../sass/components/ClientMagagerTools.scss';

const ITEMS_PER_PAGE = 20;

const mapStoreToProps = (store) => ({
  user: store.data.user,
});

class ClientMagagerTools extends React.Component {
  constructor(props) {
    super(props);
    const page = (Number(this.props.location.hash.replace(/\D/g, '')) || 1) - 1;
    this.state = {
      group_id: !props.user?.group?.admin ? props.user?.group?.id : null,
      account_number: '',
      department_id: null,
      tasks: [],
      page,
      maxPage: 0,
      groups: [],
      departments: [],
    }
  }

  componentDidMount = () => {
    this.requestGroups();
    this.requestDepartments();
  }

  requestGroups = () => {
    sendRequest({
      method: 'groups/all',
      type: 'GET',
      success: (data) => {
        this.setState({ groups: (data || []).filter(g => !g.admin) });
      },
      error: (data) => {
      }
    });
  }

  requestDepartments = () => {
    sendRequest({
      method: 'departments/all',
      type: 'GET',
      success: (data) => {
        this.setState({ departments: (data || []) });
      },
      error: (data) => {
      }
    });
  }

  requestPhotos = () => {
    const { group_id, page, account_number, department_id } = this.state;
    const data = {
      group_id,
      offset: page * ITEMS_PER_PAGE,
    };
    if (department_id) {
      data.department_id = department_id;
    } else if (account_number) {
      data.account_number = account_number.trim();
    } else {
      return;
    }
    sendRequest({
      method: 'tasks/photo_results',
      type: 'GET',
      data,
      success: (data) => {
        if (!data || !data.objects?.length) {
          this.setState({
            tasks: [],
            maxPage: 0,
            page: 0,
          });
          triggerEvent('showSnackbar', [{text: 'Фото не найдены', type: 'error'}]);
        } else {
          const maxPage = Math.ceil(data.count / ITEMS_PER_PAGE);
          this.setState({
            tasks: data.objects,
            maxPage: maxPage,
            page: Math.min(page, maxPage),
          });
        }
      },
      error: (data) => {
        this.setState({
          tasks: [],
          maxPage: 0,
          page: 0,
        });
        triggerEvent('showSnackbar', [{text: 'Фото не найдены', type: 'error'}]);
      }
    });
  }

  requestActs = () => {
    const { group_id, account_number } = this.state;
    const data = {
      group_id,
      account_number: account_number.trim(),
    };
    const link = getMethodString(apiUrl('export/acts_for_managers'), data);
    fetch(link, { headers: {
      Authorization: 'Bearer ' + localStorage.getItem('meter-inspector-admin:token'),
    }})
      .then( res => res.blob() )
      .then( blob => {
        const file = window.URL.createObjectURL(blob);
        saveAs(file, `ЛС ${account_number.trim()}.zip`);
      });
  }

  renderPagination = () => {
    if (this.state.maxPage <= 1 || !this.state.tasks.length) {
      return null;
    }
    return (
      <div className='bottomContainer'>
        <Pagination
          page={this.state.page + 1}
          maxPage={this.state.maxPage}
          onPageChange={page => {
            this.setState({page: page - 1}, this.requestPhotos);
          }}
        />
      </div>
    )
  }

  renderPhotoSearch = () => {
    const { user } = this.props;
    const {
      group_id, account_number, department_id, tasks, departments
    } = this.state;
    const filteredDepartments = departments.filter(d => d.group_id === group_id);
    return (
      <>
        <div className='searchTitle'>
          <label>Поиск фото</label>
        </div>
        <div className='searchInputs'>
          <div className='inputBlock'>
            <label>Номер Л/С</label>
            <TextInput
              properties={{
                type: 'text',
                placeholder: 'Ввести',
              }}
              object={account_number}
              onChange={(k, val) => this.setState({account_number: val, department_id: null})}
            />
          </div>
          <div className='inputBlock'>
            <label>Отделение</label>
            <SelectInput
              properties={{
                clearable: true,
                placeholder: 'Выбрать',
                options: filteredDepartments,
              }}
              object={department_id}
              onChange={(k, value) => this.setState({department_id: value, account_number: ''})}
            />
          </div>
          <button
            disabled={!group_id || (!account_number && !department_id)}
            onClick={this.requestPhotos}
          >Поиск</button>
        </div>
        <div className='tasksContainer'>
          {tasks.map(task =>
            <div key={task.id} className='taskItem'>
              {user?.role === 'client_manager' ?
                <div
                  className={classnames('taskTitle', {
                    'noBorder': task.photos.length < 2,
                  })}
                >
                  Задание №{task.id}
                </div>
                :
                <a
                  className={classnames('taskLink', {
                    'noBorder': task.photos.length < 2,
                  })}
                  href={`/tasks/${task.id}`}
                  target='_blank'
                  rel='noopener noreferrer'
                >Задание №{task.id}</a>
              }
              <div className='photosContainer'>
                {task.photos.map(i =>
                  <div
                    key={i.id}
                    className='imageItem'
                    style={{ backgroundImage: `url(${i.url})` }}
                    onClick={() => window.open(i.url, '_blank')}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        {this.renderPagination()}
      </>
    )
  }

  renderActSearch = () => {
    const { group_id, account_number } = this.state;
    return (
      <>
        <div className='searchTitle'>
          <label>Поиск актов</label>
        </div>
        <div className='searchInputs'>
          <div className='inputBlock'>
            <label>Номер Л/С</label>
            <TextInput
              properties={{
                type: 'text',
                placeholder: 'Ввести',
              }}
              object={account_number}
              onChange={(k, val) => this.setState({account_number: val, department_id: null})}
            />
          </div>
          <button
            disabled={!group_id || !account_number}
            onClick={this.requestActs}
          >Поиск</button>
        </div>
      </>
    )
  }

  render = () => {
    const { user } = this.props;
    const { group_id, groups } = this.state;
    const group = groups.find(g => g.id === group_id);
    return (
      <div className='clientMagagerTools'>
        <div className='searchInputs'>
          <div className='inputBlock'>
            <label>Группа</label>
            <SelectInput
              properties={{
                clearable: true,
                options: groups,
                placeholder: 'Выбрать',
              }}
              disabled={!user?.group?.admin}
              object={group_id}
              onChange={(k, value) => this.setState({group_id: value, tasks: []})}
            />
          </div>
        </div>
        {['кск', 'тнс'].includes(group?.code) ? this.renderPhotoSearch() : null}
        {['тнс_инст'].includes(group?.code) ? this.renderActSearch() : null}
      </div>
    )
  }
}

export default connect(mapStoreToProps)(withRouter(ClientMagagerTools));
