import React from 'react';

import '../../sass/components/common/GraphListView.scss';

export default class GraphListView extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleLineClick = (e, i) => {
    if (this.props.objectPageKey && this.props.data.ids[i]) {
      // TODO: redirect
    }
  }

  renderLine = (labelData, i) => {
    let valueData = this.props.data.datasets[0] ? this.props.data.datasets[0].data[i] : null;
    let value = null;
    if (Array.isArray(valueData)) {
      value = valueData.reverse().map((valueDatum, i) => {
        return <span key={i}>{valueDatum}</span>
      });
    } else {
      value = <span>{valueData}</span>
    }
    return (
      <div key={i} className='graphListItem'>
        <div className='valueLabel'>
          <span onMouseDown={(e) => {this.handleLineClick(e,i)}}>
            {labelData}
          </span>
        </div>
        <div className='valueLabel'>
          {value}
        </div>
      </div>
    );
  }

  render = () => {
    return (
      <div className='graphListView'>
        <h4>{this.props.options.title.text}</h4>
        {this.props.data.labels.map(this.renderLine)}
      </div>
    )
  }
}
