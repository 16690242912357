import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { saveAs } from 'file-saver';

import { sendRequest } from '../../helpers/global';

import DateInput from '../input/DateInput';

import '../../sass/components/AdminToolsView.scss';

class ExportTasks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      from: moment().subtract(1, 'day').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    }
  }

  requestTasksExport = () => {
    const { group } = this.props;
    const { from, to } = this.state;
    const method =
      group.code === 'кск' ? 'ksk' :
      group.code === 'тнс' || group.code === 'тнс_инст' ? 'tns' :
      'unknown';
    sendRequest({
      method: `export/${method}`,
      type: 'GET',
      responseType: 'blob',
      data: {
        from,
        to,
        code: group.code,
      },
      success: (data, response) => {
        var fileData = new Blob([data], {
          type: response.headers['content-type'],
        });
        const match = decodeURI(response.headers['content-disposition'])?.match(/filename=(.*)/);
        saveAs(fileData, match?.[1] || Date.now());
      },
      error: (data) => {
      }
    });
  }

  render = () => {
    const { group } = this.props;
    const { from, to } = this.state;
    return (
      <div className='toolSection'>
        <div className='toolName'>Экспорт {group?.name}</div>
        <div className='dateRange'>
          <label>Даты загрузки с</label>
          <DateInput
            properties={{
              type: 'date'
            }}
            object={from}
            onChange={(k, value) => this.setState({from: value})}
          />
          <label>до</label>
          <DateInput
            properties={{
              type: 'date'
            }}
            object={to}
            onChange={(k, value) => this.setState({to: value})}
          />
        </div>
        <button
          disabled={!from || !to}
          onClick={this.requestTasksExport}
        >Сформировать файл для {group?.name}</button>
      </div>
    )
  }
}

export default (withRouter(ExportTasks));
